let apis = {
  banner:'/banner/queryBanner',//获取首页banner
  findInfo:'/hotel/findInfo',//获取酒店内页信息
  getHotelImages:'/hotel/getHotelImages',//获取酒店相册
  listKey:'/enums/listKey',//获取所有枚举的key
  enumsList:'/enums/list',//根据key获取所有枚举
  getInstallationService:'/hotel/getInstallationService',//查询设施与服务
  queryRoomTypeList:'/hotelRoom/queryRoomTypeList',//查询房型标签
  getHotelInfoDetail:'hotel/getHotelInfoDetail',//查询酒店详情
  getBookingInstruction:'/hotel/getBookingInstruction',//查询预定须知
  slicedQueryHotelRoom:'/hotelRoom/slicedQueryHotelRoom',//查询房间列表
  findHotelRoom:'/hotelRoom/findHotelRoom',//查询房间详情
  searchHotelList:'/hotel/searchHotelList',//查询房间详情
  addCollect:'/userCollect/addCollect',//取消点赞
  cancelCollect:'/userCollect/cancelMyCollect',//取消点赞
  citys: '/baseArea/getAllArea', //获取区县及热门城市
  createOrder: '/stlsOrder/create', //创建订单
  queryPriCalendarByRoomIdAndDate: '/hotelRoom/queryPriCalendarByRoomIdAndDate', //查询价格日历
  orderListHotel: '/stlsOrder/queryHotelOrderList',//订单列表
  refundApply: '/stlsOrder/refundApply',//订单退款
  cancelOrder: '/stlsOrder/cancel',//订单取消
  findHotelOrderDetail: '/stlsOrder/findHotelOrderDetail',//订单详情
  hotelUserRefundApply: '/stlsOrder/hotelUserRefundApply',//酒店申请退款
  refundApplyCompose: '/stlsOrder/compose/refundApply',//套餐申请退款
  findreFundByOrderNumber: '/stlsOrder/findreFundByOrderNumber',//退款进度
  recallHotelUserRefundApply: '/stlsOrder/recallHotelUserRefundApply',//撤销退款申请
  revokeRefundApply: '/stlsOrder/compose/revokeRefundApply',//套餐撤销退款申请
  deleteOrder: '/stlsOrder/deleteOrder',//删除订单
  queryOrderList:'/stlsOrder/queryOrderList',//查询支付状态
  pay:"/stlsOrder/createPayInfo",
  upload:"/upload",//文件上传
  insertUserCriticism:'/userCriticism/insertUserCriticism',//添加评论
  slicedQueryUserCriticism:'/userCriticism/slicedQueryUserCriticism',//评论列表
  detail:'/stlsOrder/findOrderDetail',
  findCommodity:'/commodity/findCommodity',//获取景点信息
};

export default apis
